import axios from '../../utils/axios';

const getCouponsPerPage = async (
  page, limit, searchValue, order,
) => {
  const { data } = await axios.get(`/api/coupons?page=${page}&limit=${limit}&searchValue=${searchValue}&order=${order}`);
  return data;
};

const postCoupon = async (newData) => {
  const { data } = await axios.post('/api/coupons/create', newData);
  return data;
};

const putCoupon = async (id, newData) => {
  const { data } = await axios.put(`/api/coupons/${id}`, newData);
  return data;
};

const deleteCoupons = async (couponsIds) => {
  const { data } = await axios.put('/api/coupons/delete', { couponsIds });
  return data;
};

const getCouponById = async (id) => {
  const { data } = await axios.get(`/api/coupons/${id}`);
  return data;
};

export {
  getCouponsPerPage,
  putCoupon,
  postCoupon,
  deleteCoupons,
  getCouponById,
};
