/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Switch as MaterialSwitch,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  Edit as EditIcon,
  PlayCircle as PlayIcon,
  StopCircle as StopIcon,
  Search as SearchIcon,
  CheckCircle as CheckIcon,
  XCircle as XIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';
import IconCrownCircle from '../../../utils/crownIcon';

import handleApiResponse from '../../../utils/http';

import { deleteAudios, updateAudioRecommendation } from '../../../requests/api/audios';

import useStyles from './styles';

const sortOptions = [
  {
    value: 'ALPH',
    label: 'Alfabético',
  },
  {
    value: 'DESC',
    label: 'Más nuevo primero',
  },
  {
    value: 'ASC',
    label: 'Más antiguo primero',
  },
];

function Results({
  className,
  audios,
  query,
  setQuery,
  page,
  setPage,
  limit,
  setLimit,
  sort,
  setSort,
  totalAudios,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedAudios, setSelectedAudios] = useState([]);
  const [currentAudios, setCurrentAudios] = useState(audios);
  let playing;
  let playingAudio;

  useEffect(() => { setCurrentAudios(audios); }, [audios]);

  const toggle = async (id, url) => {
    if (playingAudio) {
      playingAudio.pause();
    }
    if (playing !== id) {
      playing = id;
      playingAudio = new Audio(url);
      playingAudio.play();
    } else {
      playing = null;
    }
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllAudios = (event) => {
    setSelectedAudios(event.target.checked
      ? currentAudios.map((audio) => audio.id)
      : []);
  };

  const toggleRecommendation = async (id, recommended) => {
    try {
      const response = await updateAudioRecommendation(id, { recommended });
      handleApiResponse(enqueueSnackbar, response, true);
      // eslint-disable-next-line array-callback-return
      currentAudios.map((video) => {
        if (video.id === id) { video.recommended = !recommended; }
      });
      setSelectedAudios([]);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, { status: 400, message: 'Ya existen 10 audios recomendados' }, false);
    }
  };

  const handleSelectOneAudio = (event, audioId) => {
    if (!selectedAudios.includes(audioId)) {
      setSelectedAudios((prevSelected) => [...prevSelected, audioId]);
    } else {
      setSelectedAudios((prevSelected) => prevSelected.filter((id) => id !== audioId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const enableBulkOperations = selectedAudios.length > 0;
  const selectedSomeAudios = selectedAudios.length > 0 && selectedAudios.length < currentAudios.length;
  const selectedAllAudios = selectedAudios.length === currentAudios.length;
  const account = useSelector((state) => state.account);

  const deleteSelectedAudios = async () => {
    if (account.user.role !== 'admin') {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      try {
        const response = await deleteAudios(selectedAudios);
        handleApiResponse(enqueueSnackbar, response, true);
        setCurrentAudios(currentAudios.filter((e) => (!selectedAudios.includes(e.id))));
        setSelectedAudios([]);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    }
  };

  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                >
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleQueryChange}
          placeholder="Buscar Audio"
          value={query}
          variant="outlined"
        />
        <Box flexGrow={1} />
        <TextField
          label="Orden"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllAudios}
              indeterminate={selectedSomeAudios}
              onChange={handleSelectAllAudios}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteSelectedAudios()}
            >
              Eliminar
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllAudios}
                    indeterminate={selectedSomeAudios}
                    onChange={handleSelectAllAudios}
                  />
                </TableCell>
                <TableCell align="center">
                  Nombre
                </TableCell>
                <TableCell align="center">
                  Creador
                </TableCell>
                <TableCell align="center">
                  Categorías
                </TableCell>
                <TableCell align="center">
                  Tiempo de Reproducción
                </TableCell>
                <TableCell align="center">
                  Publicado
                </TableCell>
                <TableCell align="center">
                  Recomendado
                </TableCell>
                <TableCell align="center">
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentAudios.map((audio) => {
                const isAudioSelected = selectedAudios.includes(audio.id);
                return (
                  <TableRow
                    hover
                    key={audio.id}
                    selected={isAudioSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isAudioSelected}
                        onChange={(event) => handleSelectOneAudio(event, audio.id)}
                        value={isAudioSelected}
                      />
                    </TableCell>
                    <TableCell>{audio.name} {audio.premium ? IconCrownCircle() : null}</TableCell>
                    <TableCell align="center">{audio.creator}</TableCell>
                    <TableCell align="center">{audio.categories}</TableCell>
                    <TableCell align="center">{audio.time} seg</TableCell>
                    <TableCell align="center">{audio.published ? (<CheckIcon />) : (<XIcon />)}</TableCell>
                    <TableCell align="center">{<MaterialSwitch onChange={() => toggleRecommendation(audio.id, audio.recommended)} checked={audio.recommended}/>}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        component={RouterLink}
                        to={`/config/audios/${audio.id}/editar`}
                        size="large">
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                      <IconButton
                        size="large"
                        onClick={() => toggle(audio.id, audio.link)}>
                        <SvgIcon fontSize="small">
                          {audio.id === playing ? <StopIcon /> : <PlayIcon />}
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalAudios}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalAudios: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
  audios: PropTypes.array,
};

Results.defaultProps = {
  audios: [],
};

export default Results;
