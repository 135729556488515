import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import Page from '../../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getCreatorsPerPage } from '../../../../requests/api/users';

import useStyles from './styles';
import handleApiResponse from '../../../../utils/http';

function CreatorListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [creators, setCreators] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCreators, setTotalCreators] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('updated_at|desc');

  useEffect(() => {
    const getCreators = async () => {
      try {
        const response = await getCreatorsPerPage(page, limit, query, sort);
        if (response !== '') {
          const data = await response.creators;
          setTotalCreators(response.totalCreators);
          const arrayData = Object.values(data);
          setCreators(arrayData);
        } else {
          handleApiResponse(enqueueSnackbar, { message: 'No se encontraron creadores' }, false);
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getCreators();
  }, [sort, query, page, limit]);

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Creators"
    >
      <Container maxWidth={false}>
        <Header />
        {creators && (
          <Box mt={3}>
            <Results
              creators={creators}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              query={query}
              setQuery={setQuery}
              sort={sort}
              totalCreators={totalCreators}
              setSort={setSort}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default CreatorListView;
