import axios from '../../utils/axios';

const getVideosPerPage = async (
  page, limit, searchValue, order,
) => {
  const { data } = await axios.get(`/api/videos?page=${page}&limit=${limit}&searchValue=${searchValue}&order=${order}`);
  return data;
};

const editVideoData = async (id, newData) => {
  const { data } = await axios.put(`/api/videos/${id}`, newData);
  return data;
};

const postCreateVideo = async (newData) => {
  const { data } = await axios.post('/api/videos/create', newData);
  return data;
};

const deleteVideos = async (videoIds) => {
  const { data } = await axios.put('/api/videos/delete', { videoIds });
  return data;
};

const getVideoDetail = async (id) => {
  const { data } = await axios.get(`/api/videos/${id}`);
  return data;
};

const updateVideoRecommendation = async (id, recommended) => {
  const { data } = await axios.put(`/api/videos/recommendation/${id}`, { recommended });
  return data;
};

export {
  getVideosPerPage,
  editVideoData,
  postCreateVideo,
  deleteVideos,
  getVideoDetail,
  updateVideoRecommendation,
};
