import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@mui/material';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Header from './Header';
import VideoEditForm from './VideoEditForm';

import useStyles from './styles';

import { getVideoDetail } from '../../../requests/api/videos';
import { getCategories } from '../../../requests/api/categories';
import { getCreatorsNames } from '../../../requests/api/users';

function VideoEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [video, setVideo] = useState();
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [usersReady, setUsersReady] = useState(false);

  useEffect(() => {
    const getVideo = async () => {
      const response = await getVideoDetail(props.match.params.id);
      if (isMountedRef.current) {
        setVideo(response[0]);
      }
    };
    getVideo();
  }, [props]);

  useEffect(() => {
    const getCategoriesData = async () => {
      const data = await getCategories();
      setCategories(data.categories);
    };
    getCategoriesData();
  }, []);

  useEffect(() => {
    const getCreatorsData = async () => {
      const data = await getCreatorsNames();
      if (!data) {
        setUsers([]);
      } else {
        setUsers(data.creators);
      }
      setUsersReady(true);
    };
    getCreatorsData();
  }, []);

  if (!video || !categories || !usersReady) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Editar Video"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <VideoEditForm video={video} creators={users} categories={categories}/>
        </Box>
      </Container>
  </Page>
  );
}

VideoEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(VideoEditView);
