import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@mui/material';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Header from './Header';
import AudioEditForm from './AudioEditForm';

import useStyles from './styles';

import { getAudioDetail } from '../../../requests/api/audios';
import { getCategories } from '../../../requests/api/categories';
import { getCreatorsNames } from '../../../requests/api/users';

function AudioEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [audio, setAudio] = useState();
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [usersReady, setUsersReady] = useState(false);

  useEffect(() => {
    const getAudio = async () => {
      const response = await getAudioDetail(props.match.params.id);
      if (isMountedRef.current) {
        setAudio(response[0]);
      }
    };
    getAudio();
  }, [props]);

  useEffect(() => {
    const getCategoriesData = async () => {
      const data = await getCategories();
      setCategories(data.categories);
    };
    getCategoriesData();
  }, []);

  useEffect(() => {
    const getCreatorsData = async () => {
      const data = await getCreatorsNames();
      if (!data) {
        setUsers([]);
      } else {
        setUsers(data.creators);
      }
      setUsersReady(true);
    };
    getCreatorsData();
  }, []);

  if (!audio || !categories || !usersReady) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Editar Audio"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <AudioEditForm audio={audio} creators={users} categories={categories} />
        </Box>
      </Container>
    </Page>
  );
}

AudioEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(AudioEditView);
