import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@mui/material';
import PropTypes from 'prop-types';
import Page from '../../../../components/Layout/Page';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import Header from './Header';
import CreatorEditForm from './CreatorEditForm';

import useStyles from './styles';

import { getCreatorDetail } from '../../../../requests/api/creators';
import { getUserNames } from '../../../../requests/api/users';

function CreatorEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [creator, setCreator] = useState();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsersData = async () => {
      const data = await getUserNames();
      setUsers(data.users);
    };
    getUsersData();
  }, []);

  useEffect(() => {
    const getCreator = async () => {
      const response = await getCreatorDetail(props.match.params.id);
      if (isMountedRef.current) {
        setCreator(response);
      }
    };

    getCreator();
  }, [props]);

  if (!creator) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Editar Creador de contenido"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <CreatorEditForm creator={creator} users={users} />
        </Box>
      </Container>
  </Page>
  );
}

CreatorEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(CreatorEditView);
