// icon:crown-circle | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from 'react';

function IconCrownCircle(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M12 2C6.47 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2m4 13.44c0 .34-.22.56-.56.56H8.56c-.34 0-.56-.22-.56-.56V15h8v.44M16 14H8L7 8l3 2 2-3 2 3 3-2-1 6z" />
    </svg>
  );
}

export default IconCrownCircle;
