import React, { useEffect } from 'react';
// import { matchPath } from 'react-router';
import {
  Avatar,
  Box,
  Button,
  Container,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Page from '../../components/Layout/Page';
import Logo from '../../components/Layout/Logo';
import authService from '../../services/authService';

import useStyles from './styles';

function MercadoPagoView() {
  const classes = useStyles();

  useEffect(() => {
    authService.logoutSession();
  });

  return (
    <Page
      className={classes.root}
      title="Manejar Suscripcion"
    >
      <Container maxWidth="md">
        <Box
          mb={8}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Logo className={classes.logo} />
        </Box>
        <Card className={classes.card} >
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <AccountBoxIcon fontSize="large" />
            </Avatar>
            <Typography
              variant="h2"
              color='primary'
            >
              ¡Suscríbete a través de MercadoPago!
            </Typography>
            <Typography
              variant="h5"
              color='Secondary'
            >
              ¡Recuerda ingresar con tu cuenta de MercadoPago y verificar tu suscripción en la App!
            </Typography>
            <Box xs={12} className={classes.box}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                onClick={() => window.open('https://www.mercadopago.cl/subscriptions/checkout?preapproval_plan_id=2c938084886bff9e01886d2b42b00064', 'self')}
              >
                Suscribirse
              </Button>
            </Box>
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
            </Typography>
            <Box my={2}>
              <Divider />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default MercadoPagoView;
