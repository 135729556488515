import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import CategoryCreateForm from './CategoryCreateForm';
import { getPillars } from '../../../requests/api/pillars';

import useStyles from './styles';

function CategoryCreateView() {
  const classes = useStyles();
  const [pillars, setPillars] = useState([]);

  useEffect(() => {
    const getPillarsData = async () => {
      const data = await getPillars();
      setPillars(data.pillars);
    };
    getPillarsData();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Agregar Categoría"
    >
      <Container maxWidth={false}>
        <Header />
        <CategoryCreateForm pillars={pillars} />
      </Container>
    </Page>
  );
}

export default CategoryCreateView;
