import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getVideosPerPage } from '../../../requests/api/videos';

import useStyles from './styles';
import handleApiResponse from '../../../utils/http';

function VideoListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [videos, setVideos] = useState();
  const [page, setPage] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('DESC');

  useEffect(() => {
    const getVideos = async () => {
      try {
        const response = await getVideosPerPage(page, limit, query, sort);
        if (response !== '') {
          const data = await response.videos;
          const arrayData = Object.values(data);
          setTotalVideos(response.totalVideos);
          const parsedVideos = arrayData.map((video) => {
            const categoriesList = video.categories.map((el) => el.name);
            const categories = categoriesList.join(', ');
            return ({
              id: video.id,
              link: video.videoUrl,
              name: video.name,
              creator: video.contentCreator?.name || '-',
              time: video.mostPlayedVideo?.total_time || 0,
              categories,
              published: video.published,
              premium: video.premium,
              recommended: video.recommended,
            });
          });
          setVideos(parsedVideos);
        } else {
          handleApiResponse(enqueueSnackbar, { message: 'No hay videos para mostrar' }, false);
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getVideos();
  }, [sort, query, page, limit]);

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Videos"
    >
      <Container maxWidth={false}>
        <Header />
        {videos && (
          <Box mt={3}>
            <Results
              videos={videos}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              query={query}
              setQuery={setQuery}
              sort={sort}
              totalVideos={totalVideos}
              setSort={setSort}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default VideoListView;
