import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import VideoCreateForm from './VideoCreateForm';
import { getCategories } from '../../../requests/api/categories';

import useStyles from './styles';
import { getCreatorsNames } from '../../../requests/api/users';

function VideoCreateView() {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersReady, setUsersReady] = useState(false);

  useEffect(() => {
    const getCategoriesData = async () => {
      const data = await getCategories();
      setCategories(data.categories);
    };
    getCategoriesData();
  }, []);

  useEffect(() => {
    const getCreatorsData = async () => {
      const data = await getCreatorsNames();
      if (!data) {
        setUsers([]);
      } else {
        setUsers(data.creators);
      }
      setUsersReady(true);
    };
    getCreatorsData();
  }, []);

  if (!usersReady) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Agregar Video"
    >
      <Container maxWidth={false}>
        <Header />
        <VideoCreateForm categories={categories} creators={users} />
      </Container>
    </Page>
  );
}

export default VideoCreateView;
