import axios from '../../utils/axios';

const getCategories = async () => {
  const { data } = await axios.get('/api/categories/all');
  return data;
};

const getCategoriesPerPage = async (
  page, limit, searchValue, order,
) => {
  const { data } = await axios.get(`/api/categories?page=${page}&limit=${limit}&searchValue=${searchValue}&order=${order}`);
  return data;
};

const editCategoryData = async (id, newData) => {
  const { data } = await axios.put(`/api/category/${id}`, newData);
  return data;
};

const postCreateCategory = async ({
  name, pillars, imageUrl, publish,
}) => {
  const { data } = await axios.post('/api/categories/create', {
    name, pillars, imageUrl, publish,
  });
  return data;
};

const deleteCategories = async (categoryIds) => {
  const { data } = await axios.put('/api/categories/delete', { categoryIds });
  return data;
};

const getCategoryDetail = async (id) => {
  const { data } = await axios.get(`/api/categories/${id}`);
  return data;
};

export {
  getCategories,
  getCategoriesPerPage,
  editCategoryData,
  postCreateCategory,
  deleteCategories,
  getCategoryDetail,
};
