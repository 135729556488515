import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { Redirect } from 'react-router';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import handleApiResponse from '../../../utils/http';
import useStyles from './styles';
import { FREE_TIME_OPTIONS } from '../../../constants/dropdownOptions';
import { postCoupon } from '../../../requests/api/coupons';

function CouponCreateForm({
  className,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [customValues, setCustomValues] = useState({
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now() + 1000 * 24 * 3600),
    freeTime: Object.keys(FREE_TIME_OPTIONS)[0] ?? 'daily',
  });

  if (submitSuccess) {
    return <Redirect to="/config/cupones" />;
  }

  return (
    <Formik
      initialValues={{
        code: '',
        description: '',
        startDate: customValues.startDate,
        endDate: customValues.endDate,
        maxUsesPerUser: 1,
        maxTotalUses: 1,
        freeTime: Object.keys(FREE_TIME_OPTIONS)[0] ?? 'daily',
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().max(255).required('Debe ingresar un código'),
        maxUsesPerUser: Yup.number().required('Debes ingresar una fecha de inicio'),
        maxTotalUses: Yup.number().min(1).required('Debes ingresar un número máximo de usos'),
      })}
      onSubmit={async (values, {
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            code: values.code,
            description: values.description,
            startDate: values.startDate,
            endDate: values.endDate,
            maxUsesPerUser: values.maxUsesPerUser,
            maxTotalUses: values.maxTotalUses,
            freeTime: values.freeTime,
          };

          const response = await postCoupon(data);

          handleApiResponse(enqueueSnackbar, response, response.success);
          setSubmitSuccess(true);
          setSubmitting(false);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >

          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Código"
                    name="code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.code}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.videoUrl && errors.videoUrl)}
                    fullWidth
                    helperText={touched.videoUrl && errors.videoUrl}
                    label="Descripción"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <DesktopDatePicker
                    inputFormat="DD/MM/yyyy"
                    minDate={new Date(Date.now())}
                    value={customValues.startDate}
                    views={['year', 'month', 'day']}
                    openTo="day"
                    onChange={(date) => {
                      setCustomValues((prev) => ({
                        ...prev,
                        startDate: date,
                      }));
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <DesktopDatePicker
                    inputFormat="DD/MM/yyyy"
                    value={customValues.endDate}
                    minDate={new Date(Date.now())}
                    views={['year', 'month', 'day']}
                    openTo="day"
                    onChange={(date) => {
                      setCustomValues((prev) => ({
                        ...prev,
                        endDate: date,
                      }));
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.maxUsesPerUser && errors.maxUsesPerUser)}
                    fullWidth
                    helperText={touched.maxUsesPerUser && errors.maxUsesPerUser}
                    label="Máximo uso por usuario"
                    name="maxUsesPerUser"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.maxUsesPerUser}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.maxTotalUses && errors.maxTotalUses)}
                    fullWidth
                    helperText={touched.maxTotalUses && errors.maxTotalUses}
                    label="Máximo uso de cupones"
                    name="maxTotalUses"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.maxTotalUses}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  alignItems='center'
                  display='flex'
                >
                  <TextField
                    error={Boolean(touched.freeTime && errors.freeTime)}
                    select
                    fullWidth
                    helperText={touched.freeTime && errors.freeTime}
                    label="Duración Cupón"
                    name="freeTime"
                    onBlur={handleBlur}
                    // onChange={(e) => handleChange(e, 'freeTime')}
                    onChange={handleChange}
                    value={values.freeTime}
                    variant="outlined"
                  >
                    {Object.entries(FREE_TIME_OPTIONS).map((val) => (
                      <MenuItem key={val[0]} value={val[0]}>
                        {`${val[1]}`}
                      </MenuItem>
                    ))}
                  </ TextField>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

CouponCreateForm.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array,
  freeTimeOptions: PropTypes.array,
};

export default CouponCreateForm;
