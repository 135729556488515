import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getAudiosPerPage } from '../../../requests/api/audios';

import useStyles from './styles';
import handleApiResponse from '../../../utils/http';

function AudioListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [audios, setAudios] = useState();
  const [page, setPage] = useState(0);
  const [totalAudios, setTotalAudios] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('DESC');

  useEffect(() => {
    const getAudios = async () => {
      try {
        const response = await getAudiosPerPage(page, limit, query, sort);
        if (response !== '') {
          const data = await response.audios;
          setTotalAudios(response.totalAudios);
          const arrayData = Object.values(data);
          const parsedAudios = arrayData.map((audio) => {
            const categoriesList = audio.categories.map((el) => el.name);
            const categories = categoriesList.join(', ');
            return ({
              id: audio.id,
              link: audio.audioUrl,
              name: audio.name,
              creator: audio.contentCreator?.name || '-',
              time: audio.mostPlayedAudio?.total_time || 0,
              categories,
              published: audio.published,
              premium: audio.premium,
              recommended: audio.recommended,
            });
          });
          setAudios(parsedAudios);
        } else {
          handleApiResponse(enqueueSnackbar, { message: 'No hay audios para mostrar' }, false);
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getAudios();
  }, [sort, query, page, limit]);

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Audios"
    >
      <Container maxWidth={false}>
        <Header />
        {audios && (
          <Box mt={3}>
            <Results
              audios={audios}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              query={query}
              setQuery={setQuery}
              sort={sort}
              totalAudios={totalAudios}
              setSort={setSort}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default AudioListView;
