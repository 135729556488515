/* eslint-disable react/display-name */
import React, { Fragment, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthGuard from './components/Auth/AuthGuard';
import DashboardLayout from './components/Navigation';
import LoadingScreen from './components/Loading/LoadingScreen';

import Login from './views/Login';
import Verification from './views/Verification';
import PasswordRecovery from './views/PasswordRecovery';
import MercadoPagoView from './views/MercadoPago';

import UserListView from './views/Admin/Users/UserListView';
import UserCreateView from './views/Admin/Users/UserCreateView';
import UserEditView from './views/Admin/Users/UserEditView';

import CreatorListView from './views/Admin/Creators/CreatorListView';
import CreatorCreateView from './views/Admin/Creators/CreatorCreateView';
import CreatorEditView from './views/Admin/Creators/CreatorEditView';

import OngListView from './views/ONGs/ongListView';
import OngCreateView from './views/ONGs/ongCreateView';
import OngEditView from './views/ONGs/ongEditView';

import CategoryListView from './views/Category/categoryListView';
import CategoryCreateView from './views/Category/categoryCreateView';
import CategoryEditView from './views/Category/categoryEditView';
import AudioListView from './views/Audio/audioListView';
import AudioCreateView from './views/Audio/audioCreateView';
import AudioEditView from './views/Audio/audioEditView';
import VideoListView from './views/Video/videoListView';
import VideoCreateView from './views/Video/videoCreateView';
import VideoEditView from './views/Video/videoEditView';
import CouponsListView from './views/Coupons/couponListView';
import CouponCreateView from './views/Coupons/couponCreateView';
import CouponEditView from './views/Coupons/couponEditView';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Redirect to="/administracion/users" />,
  },
  {
    id: 2,
    exact: true,
    path: '/ingreso',
    component: () => <Login />,
  },
  {
    id: 3,
    exact: true,
    path: '/user-verification/:token',
    component: () => <Verification />,
  },
  {
    id: 4,
    exact: true,
    path: '/reset-password/:token',
    component: () => <PasswordRecovery />,
  },
  {
    id: 5,
    exact: true,
    path: '/reset-password?token=:token',
    component: () => <Redirect to="/reset-password/:token" />,
  },
  {
    id: 6,
    exact: true,
    path: '/subscription',
    component: () => <MercadoPagoView />,
  },
  {
    path: '/administracion',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        id: 11,
        exact: true,
        path: '/administracion',
        component: () => <Redirect to="/administracion/users" />,
      },
      {
        id: 12,
        exact: true,
        path: '/administracion/users',
        component: () => <UserListView />,
      },
      {
        id: 13,
        exact: true,
        path: '/administracion/users/crear',
        component: () => <UserCreateView />,
      },
      {
        id: 14,
        exact: true,
        path: '/administracion/users/:id/editar',
        component: () => <UserEditView />,
      },
      {
        id: 15,
        exact: true,
        path: '/administracion/creators',
        component: () => <CreatorListView />,
      },
      {
        id: 16,
        exact: true,
        path: '/administracion/creators/crear',
        component: () => <CreatorCreateView />,
      },
      {
        id: 17,
        exact: true,
        path: '/administracion/creators/:id/editar',
        component: () => <CreatorEditView />,
      },

      {
        id: 18,
        component: () => <Redirect to="/administracion/users" />,
      },
    ],
  },
  {
    path: '/config',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        id: 19,
        exact: true,
        path: '/config',
        component: () => <Redirect to="/config/categorias" />,
      },
      {
        id: 20,
        exact: true,
        path: '/config/audios',
        component: () => <AudioListView />,
      },
      {
        id: 21,
        exact: true,
        path: '/config/audios/crear',
        component: () => <AudioCreateView />,
      },
      {
        id: 22,
        exact: true,
        path: '/config/audios/:id/editar',
        component: () => <AudioEditView />,
      },
      {
        id: 23,
        exact: true,
        path: '/config/categorias',
        component: () => <CategoryListView />,
      },
      {
        id: 24,
        exact: true,
        path: '/config/categorias/crear',
        component: () => <CategoryCreateView />,
      },
      {
        id: 25,
        exact: true,
        path: '/config/categorias/:id/editar',
        component: () => <CategoryEditView />,
      },
      {
        id: 26,
        exact: true,
        path: '/config/ongs',
        component: () => <OngListView />,
      },
      {
        id: 27,
        exact: true,
        path: '/config/ongs/crear',
        component: () => <OngCreateView />,
      },
      {
        id: 28,
        exact: true,
        path: '/config/ongs/:id/editar',
        component: () => <OngEditView />,
      },
      {
        id: 29,
        exact: true,
        path: '/config/videos',
        component: () => <VideoListView />,
      },
      {
        id: 30,
        exact: true,
        path: '/config/videos/crear',
        component: () => <VideoCreateView />,
      },
      {
        id: 31,
        exact: true,
        path: '/config/videos/:id/editar',
        component: () => <VideoEditView />,
      },
      {
        id: 32,
        exact: true,
        path: '/config/cupones',
        component: () => <CouponsListView />,
      },
      {
        id: 33,
        exact: true,
        path: '/config/cupones/crear',
        component: () => <CouponCreateView />,
      },
      {
        id: 34,
        exact: true,
        path: '/config/cupones/:id/editar',
        component: () => <CouponEditView />,
      },
      {
        id: 35,
        component: () => <Redirect to="/config/categorias" />,
      },
    ],
  },
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
