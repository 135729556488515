/* eslint-disable no-param-reassign */
import jwtDecode from 'jwt-decode';
import axios from '../utils/axios';
import {
  postLogin, getUserById,
} from '../requests/api/auth';

const getAccessToken = () => localStorage.getItem('accessToken');

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const { id } = jwtDecode(accessToken).user;
    localStorage.setItem('userId', id);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setAxiosInterceptors = ({ onLogout }) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        if (onLogout) {
          setSession(null);
          onLogout();
        }
      }

      return Promise.reject(error);
    },
  );
};

const loginWithEmailAndPassword = async (email, password) => {
  const response = await postLogin({
    email,
    password,
  });
  let accessToken;

  if (response.user) {
    ({ accessToken } = response);
    setSession(accessToken);
    return response;
  }
  setSession(null);
  return response;
};

const logoutSession = () => {
  setSession(null);
};

const loginInWithToken = async () => {
  const accessToken = getAccessToken();
  const { id } = jwtDecode(accessToken).user;
  try {
    const response = await getUserById(id);
    if (!response.success) {
      logoutSession();
      return null;
    }
    return response || null;
  } catch (err) {
    return null;
  }
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleAuthentication = () => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    setSession(null);
    return;
  }

  if (isValidToken(accessToken)) {
    setSession(accessToken);
  } else {
    setSession(null);
  }
};

const isAuthenticated = () => !!getAccessToken();

export default {
  setAxiosInterceptors,
  loginWithEmailAndPassword,
  loginInWithToken,
  logoutSession,
  handleAuthentication,
  isAuthenticated,
  setSession,
};
