import axios from '../../utils/axios';

const getCreatorDetail = async (id) => {
  const { data } = await axios.get(`/api/creators/${id}`);
  return data;
};

const postRegisterCreator = async ({
  name, description, bannerImageUrl, profileImageUrl, userId,
}) => {
  const { data } = await axios.post('/api/creators/register', {
    name, description, bannerImageUrl, profileImageUrl, userId,
  });
  return data;
};

const putEditCreator = async ({
  id, name, description, bannerImageUrl, profileImageUrl, userId,
}) => {
  const { data } = await axios.put(`/api/creators/edit/${id}`, {
    name, description, bannerImageUrl, profileImageUrl, userId,
  });
  return data;
};

export {
  getCreatorDetail,
  postRegisterCreator,
  putEditCreator,
};
