/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  Edit as EditIcon,
  Search as SearchIcon,
  CheckCircle as CheckIcon,
  XCircle as XIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';

import handleApiResponse from '../../../utils/http';

import { deleteCategories } from '../../../requests/api/categories';

import useStyles from './styles';

const sortOptions = [
  {
    value: 'ALPH',
    label: 'Alfabético',
  },
  {
    value: 'DESC',
    label: 'Más nuevo primero',
  },
  {
    value: 'ASC',
    label: 'Más antiguo primero',
  },
];

function Results({
  className,
  categories,
  query,
  setQuery,
  page,
  setPage,
  limit,
  setLimit,
  sort,
  setSort,
  totalCategories,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentCategories, setCurrentCategories] = useState(categories);

  useEffect(() => { setCurrentCategories(categories); }, [categories]);

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllCategories = (event) => {
    setSelectedCategories(event.target.checked
      ? currentCategories.map((category) => category.id)
      : []);
  };

  const handleSelectOneCategory = (event, categoryId) => {
    if (!selectedCategories.includes(categoryId)) {
      setSelectedCategories((prevSelected) => [...prevSelected, categoryId]);
    } else {
      setSelectedCategories((prevSelected) => prevSelected.filter((id) => id !== categoryId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const enableBulkOperations = selectedCategories.length > 0;
  const selectedSomeCategories = selectedCategories.length > 0 && selectedCategories.length < currentCategories.length;
  const selectedAllCategories = selectedCategories.length === currentCategories.length;
  const account = useSelector((state) => state.account);

  const deleteSelectedCategories = async () => {
    if (account.user.role !== 'admin') {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      try {
        const response = await deleteCategories(selectedCategories);
        handleApiResponse(enqueueSnackbar, response, true);
        setCurrentCategories(currentCategories.filter((e) => (!selectedCategories.includes(e.id))));
        setSelectedCategories([]);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    }
  };

  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                >
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleQueryChange}
          placeholder="Buscar Categoría"
          value={query}
          variant="outlined"
        />
        <Box flexGrow={1} />
        <TextField
          label="Orden"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllCategories}
              indeterminate={selectedSomeCategories}
              onChange={handleSelectAllCategories}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteSelectedCategories()}
            >
              Eliminar
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllCategories}
                    indeterminate={selectedSomeCategories}
                    onChange={handleSelectAllCategories}
                  />
                </TableCell>
                <TableCell align="center">
                  Nombre
                </TableCell>
                <TableCell align="center">
                  Pilares
                </TableCell>
                <TableCell align="center">
                  Publicada
                </TableCell>
                <TableCell align="center">
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentCategories.map((category) => {
                const isCategorySelected = selectedCategories.includes(category.id);
                return (
                  <TableRow
                    hover
                    key={category.id}
                    selected={isCategorySelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isCategorySelected}
                        onChange={(event) => handleSelectOneCategory(event, category.id)}
                        value={isCategorySelected}
                      />
                    </TableCell>
                    <TableCell align="center">{category.category}</TableCell>
                    <TableCell align="center">{category.pillars}</TableCell>
                    <TableCell align="center">{category.published ? (<CheckIcon />) : (<XIcon />)}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        component={RouterLink}
                        to={`/config/categorias/${category.id}/editar`}
                        size="large">
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalCategories}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  totalCategories: PropTypes.number.isRequired,
  setSort: PropTypes.func.isRequired,
  categories: PropTypes.array,
};

Results.defaultProps = {
  categories: [],
};

export default Results;
