/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Switch as MaterialSwitch,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  Edit as EditIcon,
  Search as SearchIcon,
  CheckCircle as CheckIcon,
  XCircle as XIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';
import IconCrownCircle from '../../../utils/crownIcon';

import handleApiResponse from '../../../utils/http';

import { deleteVideos, updateVideoRecommendation } from '../../../requests/api/videos';

import useStyles from './styles';

const sortOptions = [
  {
    value: 'ALPH',
    label: 'Alfabético',
  },
  {
    value: 'DESC',
    label: 'Más nuevo primero',
  },
  {
    value: 'ASC',
    label: 'Más antiguo primero',
  },
];

function Results({
  className,
  videos,
  query,
  setQuery,
  page,
  setPage,
  limit,
  setLimit,
  sort,
  setSort,
  totalVideos,
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [currentVideos, setCurrentVideos] = useState(videos);

  useEffect(() => { setCurrentVideos(videos); }, [videos]);

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllVideos = (event) => {
    setSelectedVideos(event.target.checked
      ? currentVideos.map((video) => video.id)
      : []);
  };

  const handleSelectOneVideo = (event, videoId) => {
    if (!selectedVideos.includes(videoId)) {
      setSelectedVideos((prevSelected) => [...prevSelected, videoId]);
    } else {
      setSelectedVideos((prevSelected) => prevSelected.filter((id) => id !== videoId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const toggleRecommendation = async (id, recommended) => {
    try {
      const response = await updateVideoRecommendation(id, { recommended });
      handleApiResponse(enqueueSnackbar, response, true);
      // eslint-disable-next-line array-callback-return
      currentVideos.map((video) => {
        if (video.id === id) { video.recommended = !recommended; }
      });
      setSelectedVideos([]);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, { status: 400, message: 'Ya existen 10 audios recomendados' }, false);
    }
  };

  const enableBulkOperations = selectedVideos.length > 0;
  const selectedSomeVideos = selectedVideos.length > 0 && selectedVideos.length < currentVideos.length;
  const selectedAllVideos = selectedVideos.length === currentVideos.length;
  const account = useSelector((state) => state.account);

  const deleteSelectedVideos = async () => {
    if (account.user.role !== 'admin') {
      enqueueSnackbar('Debe ser administrador!', {
        variant: 'error',
      });
    } else {
      try {
        const response = await deleteVideos(selectedVideos);
        handleApiResponse(enqueueSnackbar, response, true);
        setCurrentVideos(currentVideos.filter((e) => (!selectedVideos.includes(e.id))));
        setSelectedVideos([]);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    }
  };

  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                >
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleQueryChange}
          placeholder="Buscar Video"
          value={query}
          variant="outlined"
        />
        <Box flexGrow={1} />
        <TextField
          label="Orden"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllVideos}
              indeterminate={selectedSomeVideos}
              onChange={handleSelectAllVideos}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteSelectedVideos()}
            >
              Eliminar
            </Button>
          </div>
        </div>
      )}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllVideos}
                    indeterminate={selectedSomeVideos}
                    onChange={handleSelectAllVideos}
                  />
                </TableCell>
                <TableCell align="center">
                  Nombre
                </TableCell>
                <TableCell align="center">
                  Creador
                </TableCell>
                <TableCell align="center">
                  Categorías
                </TableCell>
                <TableCell align="center">
                  Tiempo de Reproducción
                </TableCell>
                <TableCell align="center">
                  Publicado
                </TableCell>
                <TableCell align="center">
                  Recomendado
                </TableCell>
                <TableCell align="center">
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentVideos.map((video) => {
                const isVideoSelected = selectedVideos.includes(video.id);
                return (
                  <TableRow
                    hover
                    key={video.id}
                    selected={isVideoSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isVideoSelected}
                        onChange={(event) => handleSelectOneVideo(event, video.id)}
                        value={isVideoSelected}
                      />
                    </TableCell>
                    <TableCell>{video.name} {video.premium ? IconCrownCircle() : null}</TableCell>
                    <TableCell align="center">{video.creator}</TableCell>
                    <TableCell align="center">{video.categories}</TableCell>
                    <TableCell align="center">{video.time} seg</TableCell>
                    <TableCell align="center">{video.published ? (<CheckIcon />) : (<XIcon />)}</TableCell>
                    <TableCell align="center">{<MaterialSwitch onChange={() => toggleRecommendation(video.id, video.recommended)} checked={video.recommended}/>}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        component={RouterLink}
                        to={`/config/videos/${video.id}/editar`}
                        size="large">
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalVideos}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  totalVideos: PropTypes.number.isRequired,
  setSort: PropTypes.func.isRequired,
  videos: PropTypes.array,
};

Results.defaultProps = {
  videos: [],
};

export default Results;
