import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@mui/material';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Header from './Header';
import CategoryEditForm from './CategoryEditForm';

import useStyles from './styles';

import { getCategoryDetail } from '../../../requests/api/categories';
import { getPillars } from '../../../requests/api/pillars';

function CategoryEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [category, setCategory] = useState();
  const [pillars, setPillars] = useState([]);

  useEffect(() => {
    const getPillarsData = async () => {
      const data = await getPillars();
      setPillars(data.pillars);
    };
    getPillarsData();
  }, []);

  useEffect(() => {
    const getCategory = async () => {
      const response = await getCategoryDetail(props.match.params.id);
      const cat = response[0];
      if (isMountedRef.current) {
        setCategory(cat);
      }
    };

    getCategory();
  }, []);

  if (!category) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Editar Categoría"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <CategoryEditForm category={category} pillars={pillars} />
        </Box>
      </Container>
  </Page>
  );
}

CategoryEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(CategoryEditView);
