import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { Redirect } from 'react-router-dom';

import { editOngData } from '../../../requests/api/ongs';

import handleApiResponse from '../../../utils/http';
import useStyles from './styles';

function OngEditForm({
  className,
  ong,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const publishOptions = { true: 'Pública', false: 'Privada' };
  const [publish, setPublish] = useState(publishOptions[ong.published]);

  if (submitSuccess) {
    return <Redirect to="/config/ongs" />;
  }

  return (
    <Formik
      initialValues={{
        name: ong.name || '',
        description: ong.description || '',
        actualDonatedPoints: ong.actualDonatedPoints || 0,
        totalDonatedPoints: ong.totalDonatedPoints || 0,
        imageUrl: ong.imageUrl || '',
        publish,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        description: Yup.string().max(255).required('Debe ingresar una descripción'),
      })}
      onSubmit={async (values, {
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            name: values.name,
            description: values.description,
            actualDonatedPoints: values.actualDonatedPoints,
            totalDonatedPoints: values.totalDonatedPoints,
            imageUrl: values.imageUrl,
            publish: publish === 'Pública',
          };

          const response = await editOngData(ong.id, data);

          handleApiResponse(enqueueSnackbar, response, true);
          setSubmitSuccess(true);
          setSubmitting(false);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >

          <Card>
            <CardContent>
            <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.imageUrl && errors.imageUrl)}
                    fullWidth
                    helperText={touched.imageUrl && errors.imageUrl}
                    label="URL Imagen"
                    name="imageUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.imageUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Puntos Donados Actuales"
                    name="actualDonatedPoints"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.actualDonatedPoints}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Puntos Donados Totales"
                    name="totalDonatedPoints"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.totalDonatedPoints}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.publish && errors.publish)}
                    select
                    fullWidth
                    helperText={touched.publish && errors.publish}
                    label="Privacidad"
                    name="publish"
                    onBlur={handleBlur}
                    onChange={(e) => setPublish(e.target.value)}
                    value={publish}
                    variant="outlined"
                  >
                    {Object.entries(publishOptions).map(([key, val]) => (
                      <MenuItem key={key} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </ TextField>
                </Grid>
                <Grid
                  item
                  md={10}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    multiline={true}
                    helperText={touched.description && errors.description}
                    label="Descripción"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

OngEditForm.propTypes = {
  className: PropTypes.string,
  ong: PropTypes.object.isRequired,
};

export default OngEditForm;
