import React from 'react';
import { Box, CircularProgress } from '@mui/material';

import useStyles from './styles';

function SplashScreen() {
  const classes = useStyles();

  return (
    <div className={classes.loadingPage}>
      <Box display="flex" justifyContent="center" mb={6}>
        {/* TODO: Actualizar logo */}
        <img className={classes.logo} src="/img/example-logo.svg" alt="logo" />
      </Box>
      <CircularProgress style={{ color: 'var(--brand-blue' }} />
    </div>
  );
}

export default SplashScreen;
