import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@mui/material';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Header from './Header';
import OngEditForm from './OngEditForm';

import useStyles from './styles';

import { getOngDetail } from '../../../requests/api/ongs';

function OngEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [ong, setOng] = useState();

  useEffect(() => {
    const getOng = async () => {
      const response = await getOngDetail(props.match.params.id);
      if (isMountedRef.current) {
        setOng(response);
      }
    };
    getOng();
  }, [props]);

  if (!ong) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Editar ONG"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <OngEditForm ong={ong} />
        </Box>
      </Container>
  </Page>
  );
}

OngEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(OngEditView);
