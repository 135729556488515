/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  // Button,
  Card,
  // Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// import { useSelector } from 'react-redux';
import {
  Edit as EditIcon,
  Search as SearchIcon,
} from 'react-feather';
// import { useSnackbar } from 'notistack';

import getInitials from '../../../../utils/getInitials';
// import handleApiResponse from '../../../../utils/http';

// import { deleteCreators } from '../../../../requests/api/creators';

import useStyles from './styles';

const sortOptions = [
  {
    value: 'updated_at|desc',
    label: 'Más nuevo primero',
  },
  {
    value: 'updated_at|asc',
    label: 'Más antiguo primero',
  },
];

function Results({
  className,
  creators,
  query,
  setQuery,
  page,
  setPage,
  limit,
  setLimit,
  sort,
  setSort,
  totalCreators,
  ...rest
}) {
  // const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  // const [selectedCreators, setSelectedCreators] = useState([]);
  const [currentCreators, setCurrentCreators] = useState(creators);

  useEffect(() => { setCurrentCreators(creators); }, [creators]);

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  // const handleSelectAllCreators = (event) => {
  //   setSelectedCreators(event.target.checked
  //     ? currentCreators.map((creator) => creator.id)
  //     : []);
  // };

  // const handleSelectOneCreator = (event, creatorId) => {
  //   if (!selectedCreators.includes(creatorId)) {
  //     setSelectedCreators((prevSelected) => [...prevSelected, creatorId]);
  //   } else {
  //     setSelectedCreators((prevSelected) => prevSelected.filter((id) => id !== creatorId));
  //   }
  // };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  // const enableBulkOperations = selectedCreators.length > 0;
  // const selectedSomeCreators = selectedCreators.length > 0 && selectedCreators.length < currentCreators.length;
  // const selectedAllCreators = selectedCreators.length === currentCreators.length;
  // const account = useSelector((state) => state.account);

  // const deleteSelectedCreators = async () => {
  //   if (account.creator.role !== 'admin') {
  //     enqueueSnackbar('Debe ser administrador!', {
  //       variant: 'error',
  //     });
  //   } else if (selectedCreators.includes(account.creator.id)) {
  //     enqueueSnackbar('No puede eliminar su propia cuenta', {
  //       variant: 'error',
  //     });
  //   } else {
  //     try {
  //       const response = await deleteCreators(selectedCreators);
  //       handleApiResponse(enqueueSnackbar, response, true);
  //       setCurrentCreators(currentCreators.filter((e) => (!selectedCreators.includes(e.id))));
  //       setSelectedCreators([]);
  //     } catch (err) {
  //       handleApiResponse(enqueueSnackbar, err, false);
  //     }
  //   }
  // };

  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                >
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleQueryChange}
          placeholder="Buscar Usuario"
          value={query}
          variant="outlined"
        />
        <Box flexGrow={1} />
        <TextField
          label="Orden"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      {/* {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
              checked={selectedAllCreators}
              indeterminate={selectedSomeCreators}
              onChange={handleSelectAllCreators}
            />
            <Button
              variant="outlined"
              className={classes.bulkAction}
              onClick={() => deleteSelectedCreators()}
            >
              Eliminar
            </Button>
          </div>
        </div>
      )} */}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllCreators}
                    indeterminate={selectedSomeCreators}
                    onChange={handleSelectAllCreators}
                  />
                </TableCell> */}
                <TableCell align="center">
                  Nombre
                </TableCell>
                <TableCell align="center">
                  Descripcion
                </TableCell>
                <TableCell align="center">
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentCreators.map((creator) => (
              // const isCreatorSelected = selectedCreators.includes(creator.id);
                  <TableRow
                    hover
                    key={creator.id}
                    // selected={isCreatorSelected}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={isCreatorSelected}
                        onChange={(event) => handleSelectOneCreator(event, creator.id)}
                        value={isCreatorSelected}
                      />
                    </TableCell> */}
                    <TableCell align="center">
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={creator.avatar}
                        >
                          {getInitials(creator.name)}
                        </Avatar>
                        <div>
                          <Typography
                            color="inherit"
                            variant="h6"
                          >
                            {creator.name}
                          </Typography>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell align="center" width='80%'>
                      {creator.description}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        component={RouterLink}
                        to={`/administracion/creators/${creator.id}/editar`}
                        size="large">
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalCreators}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  totalCreators: PropTypes.number.isRequired,
  setSort: PropTypes.func.isRequired,
  creators: PropTypes.array,
};

Results.defaultProps = {
  creators: [],
};

export default Results;
