import axios from '../../utils/axios';

const getPillars = async () => {
  const { data } = await axios.get('/api/pillars');
  return data;
};

const getPillarDetail = async (id) => {
  const { data } = await axios.get(`/api/pillars/${id}`);
  return data;
};

export {
  getPillars,
  getPillarDetail,
};
