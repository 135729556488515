import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Typography,
  TextField,
  Switch as MaterialSwitch,
} from '@mui/material';
import { Redirect } from 'react-router';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';

import handleApiResponse from '../../../utils/http';
import useStyles from './styles';
import { postCreateAudio } from '../../../requests/api/audios';

function AudioCreateForm({
  className,
  categories,
  creators,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [publish, setPublish] = useState('Privada');
  const [contentCreator, setContentCreator] = useState('');
  const publishOptions = ['Pública', 'Privada'];
  const [premium, setPremium] = useState('Gratuito');
  const premiumOptions = ['Premium', 'Gratuito'];
  const [recommended, setRecommended] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/config/audios" />;
  }

  return (
    <Formik
      initialValues={{
        name: '',
        audioUrl: '',
        imageUrl: '',
        contentCreator,
        categories,
        publish,
        premium,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
      })}
      onSubmit={async (values, {
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            name: values.name,
            audioUrl: values.audioUrl,
            imageUrl: values.imageUrl,
            contentCreator: contentCreator?.id,
            categories: selectedCategories,
            publish: publish === 'Pública',
            premium: premium === 'Premium',
            recommended,
          };

          const response = await postCreateAudio(data);

          handleApiResponse(enqueueSnackbar, response, response.success);
          setSubmitSuccess(true);
          setSubmitting(false);
        } catch (error) {
          if (error.message.split(' ')[5] === '409') {
            handleApiResponse(enqueueSnackbar, { message: 'La url del audio o la imagen no tiene el formato correcto' }, false);
          } else if (error.message.split(' ')[5] === '405') {
            handleApiResponse(enqueueSnackbar, { message: 'Ya existen 10 audios recomendados' }, false);
          } else {
            handleApiResponse(enqueueSnackbar, error, false);
          }
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >

          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.audioUrl && errors.audioUrl)}
                    fullWidth
                    helperText={touched.audioUrl && errors.audioUrl}
                    label="Url Audio"
                    name="audioUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.audioUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.imageUrl && errors.imageUrl)}
                    fullWidth
                    helperText={touched.imageUrl && errors.imageUrl}
                    label="Url Imagen"
                    name="imageUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.imageUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.contentCreator && errors.contentCreator)}
                    select
                    fullWidth
                    helperText={touched.contentCreator && errors.contentCreator}
                    label="Creador de contenido"
                    name="contentCreator"
                    onBlur={handleBlur}
                    onChange={(e) => { setContentCreator(e.target.value); }}
                    value={contentCreator}
                    variant="outlined"
                  >
                    {creators.map((val, index) => (
                      <MenuItem key={index} value={val}>
                        {`${val.name}`}
                      </MenuItem>
                    ))}
                  </ TextField>
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.publish && errors.publish)}
                    select
                    fullWidth
                    helperText={touched.publish && errors.publish}
                    label="Privacidad"
                    name="publish"
                    onBlur={handleBlur}
                    onChange={(e) => setPublish(e.target.value)}
                    value={publish}
                    variant="outlined"
                  >
                    {publishOptions.map((val, index) => (
                      <MenuItem key={index} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </ TextField>
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.premium && errors.premium)}
                    select
                    fullWidth
                    helperText={touched.premium && errors.premium}
                    label="Premium"
                    name="premium"
                    onBlur={handleBlur}
                    onChange={(e) => setPremium(e.target.value)}
                    value={premium}
                    variant="outlined"
                  >
                    {premiumOptions.map((val, index) => (
                      <MenuItem key={index} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </ TextField>
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={12}
                  alignItems='center'
                  display='flex'
                >
                  <MaterialSwitch onChange={
                    () => setRecommended(!recommended)} checked={recommended}
                  />
                  <Typography
                    variant="h5"
                    color="textSecondary"
                  >
                    Recomendado
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Autocomplete
                    id="categories-autocomplete"
                    options={categories}
                    multiple
                    name='categories'
                    value={selectedCategories}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, v) => setSelectedCategories(v)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.queryField}
                        label='Categorías asociadas'
                        variant='outlined'
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

AudioCreateForm.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array,
  creators: PropTypes.array,
};

export default AudioCreateForm;
