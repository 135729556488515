import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getCategoriesPerPage } from '../../../requests/api/categories';

import useStyles from './styles';
import handleApiResponse from '../../../utils/http';

function CategoryListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCategories, setTotalCategories] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('DESC');

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await getCategoriesPerPage(page, limit, query, sort);
        if (response !== '') {
          const data = await response.categories;
          setTotalCategories(response.totalCategories);
          const arrayData = Object.values(data);
          const parsedCategories = arrayData.map((category) => {
            const pillarsList = category.pillars.map((el) => el.name);
            const pillarsString = pillarsList.join(', ');
            return ({
              id: category.id,
              category: category.name,
              pillars: pillarsString,
              published: category.published,
            });
          });
          setCategories(parsedCategories);
        } else {
          handleApiResponse(enqueueSnackbar, { message: 'No hay categorias para mostrar' }, false);
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getCategories();
  }, [sort, query, page, limit]);

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Categorías"
    >
      <Container maxWidth={false}>
        <Header />
        {categories && (
          <Box mt={3}>
            <Results
              categories={categories}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              query={query}
              setQuery={setQuery}
              sort={sort}
              totalCategories={totalCategories}
              setSort={setSort}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default CategoryListView;
