import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getOngsPerPage } from '../../../requests/api/ongs';

import useStyles from './styles';
import handleApiResponse from '../../../utils/http';

function OngListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [ongs, setOngs] = useState([]);
  const [page, setPage] = useState(0);
  const [totalOngs, setTotalOngs] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('DESC');

  useEffect(() => {
    const getOngs = async () => {
      try {
        const response = await getOngsPerPage(page, limit, query, sort);
        if (response !== '') {
          const data = await response.ongs;
          setTotalOngs(response.totalOngs);
          const arrayData = Object.values(data);
          setOngs(arrayData);
        } else {
          handleApiResponse(enqueueSnackbar, { message: 'No hay ongs para mostrar' }, false);
        }
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getOngs();
  }, [sort, query, page, limit]);

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | ONGs"
    >
      <Container maxWidth={false}>
        <Header />
        {ongs && (
          <Box mt={3}>
            <Results
              ongs={ongs}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              query={query}
              setQuery={setQuery}
              sort={sort}
              totalOngs={totalOngs}
              setSort={setSort}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default OngListView;
