import axios from '../../utils/axios';

const getAudiosPerPage = async (
  page, limit, searchValue, order,
) => {
  const { data } = await axios.get(`/api/audios?page=${page}&limit=${limit}&searchValue=${searchValue}&order=${order}`);
  return data;
};

const editAudioData = async (id, newData) => {
  const { data } = await axios.put(`/api/audios/${id}`, newData);
  return data;
};

const postCreateAudio = async (newData) => {
  const { data } = await axios.post('/api/audios/create', newData);
  return data;
};

const deleteAudios = async (audioIds) => {
  const { data } = await axios.put('/api/audios/delete', { audioIds });
  return data;
};

const getAudioDetail = async (id) => {
  const { data } = await axios.get(`/api/audios/${id}`);
  return data;
};

const updateAudioRecommendation = async (id, recommended) => {
  const { data } = await axios.put(`/api/audios/recommendation/${id}`, { recommended });
  return data;
};

export {
  getAudiosPerPage,
  editAudioData,
  postCreateAudio,
  deleteAudios,
  getAudioDetail,
  updateAudioRecommendation,
};
