import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Page from '../../../../components/Layout/Page';
import Header from './Header';
import CreatorCreateForm from './CreatorCreateForm';
import { getUserNames } from '../../../../requests/api/users';

import useStyles from './styles';

function CreatorCreateView() {
  const classes = useStyles();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getPillarsData = async () => {
      const data = await getUserNames();
      setUsers(data.users);
    };
    getPillarsData();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Agregar Creador de Contenido"
    >
      <Container maxWidth={false}>
        <Header />
        <CreatorCreateForm users={users} />
      </Container>
    </Page>
  );
}

export default CreatorCreateView;
