import AuthService from '../services/authService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const UPDATE_PROFILE = '@account/update-profile';

export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const response = await AuthService.loginWithEmailAndPassword(email, password);
      if (response.success) {
        const { user } = response;
        const userData = {
          email: user.email,
          name: user.name,
          lastname: user.lastname,
          role: user.role,
        };
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            userData,
          },
        });
      }
      return response;
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(userData) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      userData,
    },
  });
}

export function logout() {
  return async (dispatch) => {
    AuthService.logoutSession();

    dispatch({
      type: LOGOUT,
    });
  };
}

export function register() {
  return true;
}
