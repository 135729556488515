import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Grid,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  PlusCircle as PlusCircleIcon,
} from 'react-feather';

import { useSelector } from 'react-redux';
import useStyles from './styles';

function Header({ className, ...rest }) {
  const classes = useStyles();
  const account = useSelector((state) => state.account);

  return (
    <Grid
      className={clsx(classes.rootHeader, className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Cupones
        </Typography>
      </Grid>
      <Tooltip title={account.user.role !== 'admin' ? 'Necesitas ser admin para agregar' : ''}>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            className={classes.action}
            href="/config/cupones/crear"
            disabled={account.user.role !== 'admin'}
          >
            <SvgIcon
              fontSize="small"
              className={classes.actionIcon}
            >
              <PlusCircleIcon />
            </SvgIcon>
            Agregar Cupon
          </Button>
        </Grid>
      </Tooltip>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
