import axios from '../../utils/axios';

const getUserDetail = async (id) => {
  const { data } = await axios.get(`/api/users/${id}`);
  return data;
};

const getUserNames = async () => {
  const { data } = await axios.get('/api/users/names');
  return data;
};

const editUserData = async (id, newData) => {
  const { data } = await axios.put(`/api/users/${id}`, newData);
  return data;
};

const deleteUsers = async (userIds) => {
  const { data } = await axios.delete(`/api/users/${userIds}`);
  return data;
};

const getCreatorsNames = async () => {
  const { data } = await axios.get('/api/creators/names');
  return data;
};

const getUsersPerPage = async (page, limit, searchValue, sort) => {
  const { data } = await axios.get(`/api/users?page=${page}&limit=${limit}&searchValue=${searchValue}&sort=${sort}`);
  return data;
};

const getCreatorsPerPage = async (page, limit, searchValue, sort) => {
  const { data } = await axios.get(`/api/creators?page=${page}&limit=${limit}&searchValue=${searchValue}&sort=${sort}`);
  return data;
};

export {
  getUserDetail,
  getUserNames,
  editUserData,
  deleteUsers,
  getCreatorsNames,
  getUsersPerPage,
  getCreatorsPerPage,
};
