// eslint-disable-next-line import/prefer-default-export
export const FREE_TIME_OPTIONS = {
  daily: 'Un día',
  three_day: 'Tres días',
  weekly: 'Semanal',
  monthly: 'Mensual',
  two_month: 'Dos meses',
  three_month: 'Tres meses',
  six_month: 'Seis meses',
  yearly: 'Anual',
  lifetime: 'De por vida',
};
