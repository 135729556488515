import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { Redirect } from 'react-router';
import { postRegister } from '../../../../requests/api/auth';

import handleApiResponse from '../../../../utils/http';
import useStyles from './styles';

function UserCreateForm({
  className,
  stores,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/administracion/users" />;
  }

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        lastname: '',
        role: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        lastname: Yup.string().max(255).required('Debe ingresar un apellido'),
        email: Yup.string().email('Email inválido').required('Debe ingresar email'),
      })}
      onSubmit={async (values, {
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            name: values.name,
            lastname: values.lastname,
            role: values.role,
            email: values.email,
          };

          const response = await postRegister(data);

          handleApiResponse(enqueueSnackbar, response, true);
          setSubmitSuccess(true);
          setSubmitting(false);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >

          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    helperText={touched.lastname && errors.lastname}
                    label="Apellido"
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.lastname}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Rol"
                    name="role"
                    onChange={handleChange}
                    select
                    value={values.role}
                    variant="outlined"
                    required
                  >
                    <MenuItem
                      value="admin"
                    >
                      ADMIN
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item />
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

UserCreateForm.propTypes = {
  className: PropTypes.string,
  stores: PropTypes.array,
};

export default UserCreateForm;
