import axios from '../../utils/axios';

const getOngsPerPage = async (
  page, limit, searchValue, order,
) => {
  const { data } = await axios.get(`/api/ongs?page=${page}&limit=${limit}&searchValue=${searchValue}&order=${order}`);
  return data;
};

const editOngData = async (id, newData) => {
  const { data } = await axios.put(`/api/ongs/${id}`, newData);
  return data;
};

const postCreateOng = async ({
  name, description, imageUrl, publish,
}) => {
  const { data } = await axios.post('/api/ongs/create', {
    name, description, imageUrl, publish,
  });
  return data;
};

const deleteOngs = async (ongIds) => {
  const { data } = await axios.put('/api/ongs/delete', { ongIds });
  return data;
};

const getOngDetail = async (id) => {
  const { data } = await axios.get(`/api/ongs/${id}`);
  return data;
};

export {
  getOngsPerPage,
  editOngData,
  postCreateOng,
  deleteOngs,
  getOngDetail,
};
