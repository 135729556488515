import React, { useEffect } from 'react';
import { matchPath, useHistory } from 'react-router';
import {
  Avatar,
  Box,
  Container,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Page from '../../components/Layout/Page';
import Logo from '../../components/Layout/Logo';
import SetPasswordForm from './SetPasswordForm';
import authService from '../../services/authService';

import useStyles from './styles';

function VerificationView() {
  const classes = useStyles();
  const history = useHistory();

  const handleSubmitSuccess = () => {
    history.push('/ingreso');
  };

  const match = matchPath(window.location.pathname, {
    path: '/user-verification/:token',
    exact: true,
    strict: false,
  });

  const { token } = match.params;

  useEffect(() => {
    authService.logoutSession();
  });

  return (
    <Page
      className={classes.root}
      title="User verification"
    >
      <Container maxWidth="md">
        <Box
          mb={8}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Logo className={classes.logo} />
        </Box>
        <Card className={classes.card} >
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <AccountBoxIcon fontSize="large" />
            </Avatar>
            <Typography
              variant="h2"
              color='primary'
            >
              Verifica tu cuenta
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              Ingresa tu nueva contraseña
            </Typography>
            <Box mt={3}>
              <SetPasswordForm onSubmitSuccess={handleSubmitSuccess} token={token} />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default VerificationView;
