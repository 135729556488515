import axios from '../../utils/axios';

const postLogin = async ({
  email, password,
}) => {
  const { data } = await axios.post('/api/login', {
    email,
    password,
  });
  return data;
};

const postRegister = async ({
  name, lastname, email, role,
}) => {
  const { data } = await axios.post('/api/register', {
    name, lastname, email, role,
  });
  return data;
};

// RECOVER PASSWORD
const postRecoverPassword = async (email) => {
  const { data } = await axios.post('/api/recover-password', {
    email,
  });
  return data;
};

// UPDATE PASSWORD
const updatePassword = async ({ token, password, confirmPassword }) => {
  const { data } = await axios.post('/api/reset-password', {
    token,
    password,
    confirmPassword,
  });
  return data;
};

// USER VERIFICATION
const userVerification = async ({ token, password, confirmPassword }) => {
  const { data } = await axios.post('/api/user-verification', {
    token,
    password,
    confirmPassword,
  });
  return data;
};

// OBTAINING ACTIVE USER DATA
const getUserById = async (userId) => {
  const { data } = await axios.get(`/api/users/${userId}`);
  return data;
};

export {
  postLogin,
  postRegister,
  postRecoverPassword,
  updatePassword,
  userVerification,
  getUserById,
};
