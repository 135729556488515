import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import Page from '../../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getUsersPerPage } from '../../../../requests/api/users';

import useStyles from './styles';
import handleApiResponse from '../../../../utils/http';

function UserListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('updated_at|desc');

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await getUsersPerPage(page, limit, query, sort);
        const data = await response.users;
        setTotalUsers(response.totalUsers);
        const arrayData = Object.values(data);
        setUsers(arrayData);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getUsers();
  }, [sort, query, page, limit]);

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Users"
    >
      <Container maxWidth={false}>
        <Header />
        {users && (
          <Box mt={3}>
            <Results
              users={users}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              query={query}
              setQuery={setQuery}
              sort={sort}
              totalUsers={totalUsers}
              setSort={setSort}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default UserListView;
