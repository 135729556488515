import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Page from '../../../components/Layout/Page';
import Header from './Header';

import useStyles from './styles';
import CouponEditForm from './CouponEditForm';
import { getCouponById } from '../../../requests/api/coupons';
import SplashScreen from '../../../components/Loading/SplashScreen';

function CouponEditView(props) {
  const classes = useStyles();
  const [coupon, setCoupon] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getCouponDetail = async () => {
      const data = await getCouponById(props.match.params.id);
      setCoupon(data);
      setLoading(false);
    };
    getCouponDetail();
  }, [props]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <Page
      className={classes.root}
      title="Raíz Bienestar | Editar Cupón"
    >
      <Container maxWidth={false}>
        <Header />
        <CouponEditForm coupon={coupon} />
      </Container>
    </Page>
  );
}

CouponEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(CouponEditView);
